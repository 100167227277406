import React, { useState, useCallback, useEffect } from 'react';
import {
	RiArrowRightLine,
	RiArrowRightCircleLine,
	RiArrowLeftCircleLine
} from 'react-icons/ri';
import ReactHtmlParser from 'react-html-parser';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, Image } from 'pure-react-carousel';
import { GApageView } from '../../services/analytics';

import 'pure-react-carousel/dist/react-carousel.es.css';

import { useLanguage } from '../../hooks/LanguageProvider';

import api from '../../services/api';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import LoadingPage from '../../components/LoadingPage';
import ResponsiveContainer from '../../components/ResponsiveContainer';

import {
	Container,
	Bg,
	Title,
	Timeline,
	TimelineContent,
	CustomResponsiveContainer
} from './styles';

interface ProjectProp {
	id: number;
	projects_ref_id: number;
	name: string;
	start_year: number;
	finish_year?: number;
	cover?: string;
	presentation: string;
	description: string;
	in_progress: boolean;
}

interface GalleryProp {
	id: number;
	id_content: number;
	page: string;
	folder: string;
	picture: string;
	ext: string;
	subtitle?: string;
}

const Projects: React.FC = () => {
	const [showModal, setShowModal] = useState(true);
	const [contentModal, setContentModal] = useState<ProjectProp>();
	const [projects, setProjects] = useState<ProjectProp[]>([]);
	const [loadingPage, setLoadingPage] = useState(true);
	const [currentGallery, setCurrentGallery] = useState<GalleryProp[]>()

	const {language: languageProvider } = useLanguage();

	const titlesPage = [
		{language:"pt-BR", title:"Projetos"},
		{language:"en-US", title:"Projects"},
	];

	const showMoreDictionary = [
		{language:"pt-BR", name:"Saiba Mais"},
		{language:"en-US", name:"Show More"},
		{language:"cn", name:"Show More"},
	]

	const title = titlesPage.filter(el => el.language === languageProvider);
	const showMore = showMoreDictionary.filter(el => el.language === languageProvider);

	const handleModal = useCallback((event: React.MouseEvent<HTMLSpanElement>, proj: ProjectProp) => {
		const projString = event.currentTarget.dataset.proj;

		if (projString) {
			const proj: ProjectProp = JSON.parse(projString);

			let languagePage = languageProvider === "cn" ? "en-US": languageProvider;

			let modalContainer = document.querySelector(".modalContainer");

			modalContainer?.classList.toggle("on", showModal);

			document.body.style.overflow = showModal ? "hidden" : "initial";

			setContentModal(proj);
			setShowModal(!showModal);

			api.get(`/galleries/lang/${languagePage}/projects/${proj.projects_ref_id}`)
				.then((response) => {
					setCurrentGallery(response.data);
				}
			);
		}
	}, [languageProvider, showModal]);

	useEffect(()=> {
		if (languageProvider) {
			let languageThisPage = languageProvider === "cn" ? "en-US": languageProvider;

			api.get(`/projects/lang/${languageThisPage}`).then((response) => {
				setProjects(response.data);
				setLoadingPage(false);

			});
		}

		GApageView("institucional/projetos")
	},[languageProvider]);

	if(loadingPage) {
		return <LoadingPage />;
	}

	return (
		<Container>
			<Header />

			<Bg>
				<Title>
					<h1>{title[0]?.title}</h1>
					<span className="dividerBlue"></span>
				</Title>

				<Timeline>
					<CustomResponsiveContainer>
						{
							projects.map((project) => {
								if (project.in_progress) {
									return (
										<TimelineContent key={project.id}>
											<div className="timelineLeft">
												<div className="yearsUnit">
													{project.start_year}
													{project.finish_year && ` - ${project.finish_year}`}
												</div>
											</div>

											<div className="timelineRight" onClick={(event) => handleModal(event, project)}>
												<div className="yearsUnitMobile">
													{project.start_year}
													{project.finish_year && ` - ${project.finish_year}`}
												</div>

												<div className="unitCard">
													<h2>{project.name}</h2>

													<div className="unitContent">
														{project.cover &&
															(
																<div className="unitImg">
																	<img src={project.cover} alt={project.name} />
																</div>
															)
														}

														<div className="unitText">
															{ReactHtmlParser(project.presentation)}

															<div className="showMore">
																<span>
																	{showMore[0]?.name}
																	<RiArrowRightLine size={18} />
																</span>
															</div>
														</div>
													</div>
												</div>
											</div>

											<div className="modalContainer">
												<div className="modalUnit">
												<span className="closeModal" onClick={(event) => handleModal(event, project)}>X</span>

												<div className="contentUnit">
													<span className="yearsUnit">{contentModal?.start_year} - {contentModal?.finish_year}</span>

													<h2>{ReactHtmlParser(contentModal?.name || "")}</h2>

													<div className="textUnit">
													{ReactHtmlParser(contentModal?.description || "")}

													<div className="containerSlider">
														<CarouselProvider
														naturalSlideWidth={800}
														naturalSlideHeight={600}
														totalSlides={currentGallery?.length || 0}
														infinite
														>
														<Slider>
															{
															currentGallery && currentGallery.map((gallery, index) => (
																<Slide key={gallery.id} index={index}>
																<div className="currentSlide">
																	<Image
																	src={`${process.env.REACT_APP_URL_IMG}/pages/projects/${gallery.folder}/${gallery.picture}${gallery.ext}`}
																	alt={gallery.subtitle}
																	hasMasterSpinner
																	/>

																	<div className="buttonsController">
																	<ButtonBack><RiArrowLeftCircleLine size={24}/></ButtonBack>
																	<span>{index+1} / {currentGallery.length}</span>
																	<ButtonNext><RiArrowRightCircleLine size={24}/></ButtonNext>
																	</div>

																	<div className="carousel-caption">
																	<p>{gallery.subtitle}</p>
																	</div>
																</div>
																</Slide>
															))
															}
														</Slider>
														</CarouselProvider>
													</div>
													</div>
												</div>
												</div>
											</div>
										</TimelineContent>
									);
								} else {
									return "";
								}
							})
						}
					</CustomResponsiveContainer>

					<CustomResponsiveContainer>
						{
							projects.map((project) => {
								if (!project.in_progress){
									return (
										<TimelineContent key={project.id}>
											<div className="timelineLeft">
												<div className="yearsUnit">
													{project.start_year}
													{project.finish_year && ` - ${project.finish_year}`}
												</div>
											</div>

											<div className="timelineRight" onClick={(event) => event && handleModal(event, project)}>
												<div className="yearsUnitMobile">
													{project.start_year}
													{project.finish_year && ` - ${project.finish_year}`}
												</div>

												<div className="unitCard">
													<h2>{project.name}</h2>

													<div className="unitContent">
														{project.cover &&
															(
																<div className="unitImg">
																	<img src={project.cover} alt={project.name} />
																</div>
															)
														}

														<div className={project.cover ? "unitText" : "unitText noImg"}>
															{ReactHtmlParser(project.presentation)}
														</div>
													</div>

													<div className="showMore">
														<span>
															{showMore[0]?.name}
															<RiArrowRightLine size={18} />
														</span>
													</div>
												</div>
											</div>

											<div className="modalContainer">
												<div className="modalUnit">
													<span className="closeModal" onClick={(event) => event && handleModal(event, project)}>X</span>

													<div className="contentUnit">
														<span className="yearsUnit">{contentModal?.start_year} - {contentModal?.finish_year}</span>

														<h2>{ReactHtmlParser(contentModal?.name || "")}</h2>

														<div className="textUnit">
															{ReactHtmlParser(contentModal?.description || "")}

															<div className="containerSlider">
																<CarouselProvider
																	naturalSlideWidth={800}
																	naturalSlideHeight={600}
																	totalSlides={currentGallery?.length || 0}
																	infinite
																	isIntrinsicHeight
																>
																	<Slider>
																		{
																			currentGallery && currentGallery.map((gallery, index) => (
																				<Slide key={gallery.id} index={index}>
																					<div className="currentSlide">
																						<Image
																							src={`${process.env.REACT_APP_URL_IMG}/pages/projects/${gallery.folder}/${gallery.picture}${gallery.ext}`}
																							alt={gallery.subtitle}
																							hasMasterSpinner
																						/>

																						<div className="buttonsController">
																							<ButtonBack><RiArrowLeftCircleLine size={24}/></ButtonBack>
																							<span>{index+1} / {currentGallery.length}</span>
																							<ButtonNext><RiArrowRightCircleLine size={24}/></ButtonNext>
																						</div>

																						<div className="carousel-caption">
																							<p>{gallery.subtitle}</p>
																						</div>
																					</div>
																				</Slide>
																			))
																		}
																	</Slider>
																</CarouselProvider>
															</div>
														</div>
													</div>
												</div>
											</div>
										</TimelineContent>
									);
								} else {
									return "";
								}
							})
						}

					</CustomResponsiveContainer>
				</Timeline>

				<Footer />
			</Bg>
		</Container>
	);
}

export default Projects;
