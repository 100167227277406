import ReactGA from 'react-ga';

export const initGA = () => {
  ReactGA.initialize(`${process.env.REACT_APP_ID_GOOGLE_ANALYTICS}`);
}

export const GApageView = (page: string) => {
  ReactGA.pageview(page);
}

export const GAmodalView = (modal: string) => {
  ReactGA.modalview(modal);
};
