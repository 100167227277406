import styled from 'styled-components';

import background from '../../assets/bg_units_projects.png';

export const Container = styled.div`
	width: 100vw;
	overflow-x: hidden;
`;

export const Bg = styled.div`
	@media only screen and (min-width: 577px) {
		background-color: #F0F0F5;
		background: url(${background});
	}

	@media only screen and (min-width: 1910px) {
		background-repeat: repeat-y;
		background-position: center top;
	}
`;

export const Title = styled.div`
	width: 100%;
	padding-top: 30px;
	margin-bottom: 30px;
	overflow: hidden;

	display: flex;
	flex-direction: column;
	align-items: center;

	h1, h2 {
		padding: 10px 40px;
		text-transform: uppercase;
		color: #1E2B6E;
	}

	span {
		width: 150px;
		height: 2px;
		margin: 8px auto;
	}

	.dividerBlue {
		background-color: #1E2B6E;
	}

	.dividerGreen {
		background-color: #5A892F;
	}

	.dividerOrange {
		background-color: #f09f30;
	}
`;

export const Timeline = styled.section`
	margin: 40px 0;

	display: flex;
	place-content: center;
`;

export const TimelineContent = styled.div`
	width: 100%;
	cursor: pointer;

	display: flex;
	align-items: flex-start;
	justify-content: center;

	.timelineLeft {
		width: 180px;
		position: relative;

		display: flex;
		justify-content: flex-end;

		.yearsUnit {
			padding: 10px 12px;
			margin: 0;

			background-color: #F09F30;
			color: #000;
			border-radius: 8px;
			font-weight: bold;
			font-size: 18px;

			position: relative;
			top: 20px;
		}

		@media (max-width: 683px) {
			width: 0px;

			.yearsUnit {
				display: none;
			}
		}
	}

	.timelineRight {
		flex: 1;
		padding-left: 20px;
		padding-bottom: 50px;
		position: relative;

		display: flex;
		flex-direction: column;

		.yearsUnitMobile {
			display: none;
			padding: 5px 8px;
			background-color: #F09F30;
			color: #fff;
			border-radius: 8px;
			font-weight: bold;

			position: relative;
			top: 20px;
			margin-right: 20px;

			@media (max-width: 683px) {
			display: block;
			}
		}

		.showMore {
			width: 100%;
			margin: 15px 0 10px;
			color: #F09F30;

			display: flex;
			align-items: center;
			justify-content: flex-end;

			span {
				font-style: italic;

				display: flex;
				align-items: center;
			}

			svg {
				margin: 0 10px;
			}
		}
	}

	.unitCard {
		background-color: #ffffff8f;
		padding: 10px;

		border-radius: 36px;
		border-left: 5px solid #F09F30;
		border-right: 5px solid #F09F30;
		box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.2);

		@media (max-width: 683px) {
			padding-top: 20px;
		}

		&:hover {
			background-color: #f5f5f5;
			position: relative;
			top: -10px;
		}

		h2 {
			margin: 8px auto;
			font-size: 21px;
			line-height: 28px;

			@media (max-width: 623px) {
				margin-top: 15px;
				line-height: 24px;
			}
		}

		.unitContent {
			display: flex;
			align-items: stretch;

			@media (max-width: 683px) {
				flex-direction: column;
				align-items: center;
			}

			.unitImg {
				margin-right: 10px;

				display: flex;
				align-items: center;
				justify-content: center;

				img {
					width: auto;
					height: 300px;
					border-radius: 4px;
					align-self: center;
					margin: 0px auto 10px;

					@media (max-width: 1500px) {
					height: 270px;
					}

					@media (max-width: 1268px) {
					height: 200px;
					}

					@media (max-width: 1000px) {
					height: 170px;
					}

					@media (max-width: 840px) {
					height: 140px;
					}

					@media (max-width: 767px) {
					height: 110px;
					}

					@media (max-width: 683px) {
					height: 200px;
					}

					@media (max-width: 523px) {
					width: 90%;
					height: auto;
					}
				}
			}

			.unitText {
				height: 300px;
				padding: 2px 20px 0 10px;
				overflow-y: hidden;

				display:  flex;
				flex-direction: column;
				justify-content: center;

				p {
					font-size: 1.1em;
					line-height: 1.4em;
					text-align: justify;
					font-style: italic;
				}

				@media (max-width: 1500px) {
					height: 300px;
					padding-top: 10px;

					p {
						font-size: 1em;
						line-height: 1.2em;
					}
				}

				@media (max-width: 1268px) {
					height: 200px;
				}

				@media (max-width: 1000px) {
					min-height: 170px;
					height: auto;
				}

				@media (max-width: 840px) {
					min-height: 140px;
					height: auto;
				}

				@media (max-width: 767px) {
					min-height: 110px;
					height: auto;

					p {
						font-size: .8em;
						line-height: 1.1em;
					}
				}

				@media (max-width: 683px) {
					min-height: auto;
				}
			}
		}
	}

	.modalContainer.on {
		display: flex;
	}

	.modalContainer {
		width: 100%;
		height: 100%;
		cursor: default;
		background: #000000a8;

		position: fixed;
		top: 0;
		z-index: 10;

		display: none;
		align-items: center;
		justify-content: center;
	}

	.modalUnit {
		width: 95%;
		max-width: 950px;
		height: 85%;
		padding: 20px 15px 20px 20px;
		border-radius: 8px;
		background-color: #F0F0F5;
		box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.2);
		overflow-y: initial;
		overflow-x: hidden;
		position: absolute;

		.closeModal {
			padding: 10px 13px;
			border-radius: 50%;
			background-color: #F09F30;
			color: #f0f0f5;
			font-size: 22px;
			cursor: pointer;
			z-index: 12;

			position: fixed;
			top: 40px;
			margin-left: 901px;

			@media (max-width: 992px){
				right: 9px;
				margin-left: 0;
			}

			&:hover {
				font-size: 23px;
				font-weight: bold;
				color: #0F2A9A;
			}
		}
	}

	.contentUnit {
		height: 100%;
		padding-top: 8px;

		.yearsUnit {
			font-size: 18px;
			font-weight: bold;
			padding: 5px 8px;
			border-radius: 8px;
			background-color: #F09F30;
			color: #fff;
		}

		h2 {
			margin: 20px auto;
			font-size: 24px;
			line-height: 32px;

			@media (max-width: 615px) {
				font-size: 18px;
			}
		}

		.textUnit {
			height: auto;

			p {
				padding-right: 10px;
			}
		}

		.containerSlider {
			width: 100%;
			padding-top: 30px;

			.buttonsController {
				width: 100%;
				margin: 10px auto 0px;

				display: flex;
				align-items: center;
				justify-content: center;

				span {
					margin: 0 10px;
				}

				button {
					border: 0;
					background: transparent;
					color: #0F2A9A;

					display: flex;
					align-items: center;

					&:hover {
						color: #FF7F00;
					}
				}
			}

			.carousel-caption {
				width: 100%;
				margin-top: 5px;

				display: flex;
				justify-content: center;

				p {
					width: 90%;

					font-size: 16px;
					font-style: italic;
					text-align: center;

					@media (max-width: 610px) {
						line-height: 18px;
					}
				}
			}
		}
	}
`;

export const CustomResponsiveContainer = styled.div`
	width: 90%;
	max-width: 1920px;
`;



